//============================================================
// appsolut Theme VIENNA - v1.0.0
//============================================================
//
// Based on Otis Kit PRO - v2.0.1 from www.creative-tim.com
//
//============================================================

/**
 * The base breakpoints for the Otis Kit PRO.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire Otis Kit PRO using thie file.
 */

export default {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
};
