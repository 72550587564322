import * as React from 'react';
import { SettingsProvider } from './src/context/SettingsContext';
import { AuthProvider } from './src/context/AuthContext';
import { readFromLocalStore } from './src/store/localStore';

const defaultSettings = {
  isDarkThemeActive: false,
  menuTree: {},
  menuCollapsed: {},
  caller: {},
  product: {},
};

export const wrapRootElement = ({ element }) => {
  try {
    // get settings from store
    const storedSettings = readFromLocalStore('settings');
    let settings = { ...defaultSettings };
    if (storedSettings) settings = JSON.parse(storedSettings);

    // get user from store
    const storedUser = readFromLocalStore('user');
    let user = {};
    if (storedUser) user = JSON.parse(storedUser);

    // set context providers
    return (
      <SettingsProvider settings={settings}>
        <AuthProvider user={user}>{element}</AuthProvider>
      </SettingsProvider>
    );
  } catch (err) {
    return (
      <SettingsProvider settings={settings}>
        <AuthProvider user={user}>{element} </AuthProvider>
      </SettingsProvider>
    );
  }
};

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This tutorial has been updated. ` + `Reload to display the latest version?`
  );
  if (answer === true) {
    window.location.reload();
  }
};
