//============================================================
// appsolut Theme VIENNA - v1.0.0
//============================================================
//
// Based on Otis Kit PRO - v2.0.1 from www.creative-tim.com
//
//============================================================

// Otis Kit PRO base styles
import typography from '../../base/typography';
import colors from '../../base/colors';

// Otis Kit PRO helper functions
import pxToRem from '../../functions/pxToRem';
import rgba from '../../functions/rgba';

const { size, fontWeightRegular, fontWeightBold } = typography;
const { black } = colors;

export default {
  styleOverrides: {
    label: {
      marginTop: `${pxToRem(8)} !important`,
      fontWeight: fontWeightRegular,
      fontSize: size.xs,
      //color: '#9fc9ff',
      color: `${rgba(black.main, 0.5)}`,
      textTransform: 'uppercase',

      '&.Mui-active': {
        //fontWeight: `${fontWeightRegular} !important`,
        //color: `${rgba(white.main, 0.8)} !important`,
        fontWeight: `${fontWeightBold} !important`,
        color: `${rgba(black.main, 0.8)} !important`,
      },

      '&.Mui-completed': {
        //fontWeight: `${fontWeightRegular} !important`,
        //color: `${rgba(white.main, 0.8)} !important`,
        fontWeight: `${fontWeightRegular} !important`,
        color: `${rgba(black.main, 0.8)} !important`,
      },
    },
  },
};
