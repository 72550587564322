//============================================================
// appsolut Theme VIENNA - v1.0.0
//============================================================
//
// Based on Otis Kit PRO - v2.0.1 from www.creative-tim.com
//
//============================================================

export default {
  defaultProps: {
    underline: 'none',
    color: 'inherit',
  },
};
