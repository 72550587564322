//============================================================
// appsolut Theme VIENNA - v1.0.0
//============================================================
//
// Based on Otis Kit PRO - v2.0.1 from www.creative-tim.com
//
//============================================================

// Otis Kit PRO base styles
import colors from '../../base/colors';

// Otis Kit PRO helper functions
import pxToRem from '../../functions/pxToRem';
import boxShadow from '../../functions/boxShadow';

const { white } = colors;

export default {
  styleOverrides: {
    root: {
      color: '#9fc9ff', // new
      //background: '#9fc9ff',
      //fill: '#9fc9ff',
      //stroke: '#9fc9ff',
      //strokeWidth: pxToRem(10),
      width: '1.5rem',
      height: '1.5rem',
      borderRadius: '50%',
      zIndex: 99,
      transition: 'all 200ms linear',

      '&.Mui-active': {
        color: '#1976d2', // new
        //background: white.main,
        //fill: white.main,
        stroke: white.main,
        //borderColor: white.main,
        //boxShadow: boxShadow([0, 0], [0, 2], white.main, 1),
      },

      '&.Mui-completed': {
        color: '#1976d2', // new
        //background: white.main,
        //fill: white.main,
        //stroke: white.main,
        //borderColor: white.main,
        //boxShadow: boxShadow([0, 0], [0, 2], white.main, 1),
      },
    },
  },
};
