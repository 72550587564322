export const writeToLocalStore = (key, value) => {
  try {
    if (typeof window !== 'undefined') {
      // only use at browser
      window.localStorage.setItem(key, value);
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const readFromLocalStore = (key) => {
  try {
    if (typeof window !== 'undefined') {
      // only use at browser
      const value = window.localStorage.getItem(key);
      return value;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const removeFromLocalStore = (key) => {
  try {
    if (typeof window !== 'undefined') {
      // only use at browser
      window.localStorage.removeItem(key);
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
