//============================================================
// appsolut Theme VIENNA - v1.0.0
//============================================================
//
// Based on Otis Kit PRO - v2.0.1 from www.creative-tim.com
//
//============================================================

// Otis Kit PRO base styles
import colors from '../base/colors';

// Otis Kit PRO helper functions
import pxToRem from '../functions/pxToRem';

const { white } = colors;

export default {
  '.swiper-button-prev, .swiper-button-next': {
    position: 'absolute',
    top: '50%',
    padding: `0 ${pxToRem(64)}`,
    color: white.main,
    opacity: 0.5,
    transform: 'translateY(-250%)',
    transition: 'opacity 0.15s ease',

    '&::after': {
      fontSize: pxToRem(28),
    },

    '&:hover, &:focus': {
      opacity: 1,
    },
  },
};
