//============================================================
// appsolut Theme VIENNA - v1.0.0
//============================================================
//
// Based on Otis Kit PRO - v2.0.1 from www.creative-tim.com
//
//============================================================

// Otis Kit PRO base styles
import boxShadows from '../../base/boxShadows';
import typography from '../../base/typography';
import colors from '../../base/colors';
import borders from '../../base/borders';

// Otis Kit PRO helper functions
import pxToRem from '../../functions/pxToRem';

const { lg } = boxShadows;
const { size } = typography;
const { text, white } = colors;
const { borderRadius } = borders;

export default {
  defaultProps: {
    disableAutoFocusItem: true,
  },

  styleOverrides: {
    paper: {
      minWidth: pxToRem(160),
      boxShadow: lg,
      padding: `${pxToRem(16)} ${pxToRem(8)}`,
      fontSize: size.sm,
      color: text.main,
      textAlign: 'left',
      backgroundColor: `${white.main} !important`,
      borderRadius: borderRadius.md,
    },
  },
};
