//============================================================
// appsolut Theme VIENNA - v1.0.0
//============================================================
//
// Based on Otis Kit PRO - v2.0.1 from www.creative-tim.com
//
//============================================================

// Otis Kit PRO base styles
import colors from '../base/colors';
import typography from '../base/typography';

const { grey } = colors;
const { size } = typography;

export default {
  styleOverrides: {
    li: {
      lineHeight: 0,
    },

    separator: {
      fontSize: size.sm,
      color: grey[600],
    },
  },
};
