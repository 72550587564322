//============================================================
// appsolut Theme VIENNA - v1.0.0
//============================================================
//
// Based on Otis Kit PRO - v2.0.1 from www.creative-tim.com
//
//============================================================

// Otis Kit PRO helper functions
import pxToRem from '../../functions/pxToRem';

export default {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
    },
  },
};
