import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { writeToLocalStore } from '../store/localStore';
import { removeFromLocalStore } from '../store/localStore';

const AuthContext = createContext();

export function AuthProvider({ user, children }) {
  const [currentUser, setCurrentUser] = useState(user || {});

  const handleSaveUser = (user = {}) => {
    setCurrentUser(user);
    writeToLocalStore('user', JSON.stringify(user));
  };

  const handleRemoveUser = () => {
    setCurrentUser({});
    removeFromLocalStore('user');
  };

  const handleUserValid = () => {
    // key = Personalnummer
    const hasKey = currentUser && currentUser.key ? true : false;
    // timestamp
    const referenceTime = new Date(new Date().setHours(new Date().getHours() - 2));
    const userTime = new Date(currentUser.timestamp);
    const hasValidTimestamp = userTime > referenceTime;

    // console.log('=== VALIDATION ===');
    // console.log(`referenceTime: ${referenceTime}`);
    // console.log(`userTime: ${userTime}`);
    // console.log(`hasValidTimestamp: ${hasValidTimestamp}`);

    return hasKey && hasValidTimestamp;
  };

  return (
    <AuthContext.Provider
      value={{
        user: currentUser,
        saveUser: handleSaveUser,
        removeUser: handleRemoveUser,
        isValid: handleUserValid,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object,
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
